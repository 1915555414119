export const routes = [
  {
    path: "/layout",
    name: "layout",

    component: () => import("../views/Layout.vue"),
  },
  {
    path: "/pedalajunto",
    name: "pedalajunto",
    meta: {
      title: "pedalajunto - Seguro para bicicleta",
    },
    component: () => import("../views/Embaixador.vue"),
  },
  {
    path: "/",
    name: "login-padrao",
    meta: {
      title: "Login Cotacao - Seguro para bicicleta",
    },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/pagamento",
    name: "pagamento",
    meta: {
      title: "Pagamento - Seguro",
    },
    component: () => import("../views/Pagamento.vue"),
  },
  {
    name: "pagamento-registro",
    path: "/pagamento-registro/:id([a-fA-F0-9-]+)",
    meta: {
      title: "Pagamento - Registro",
    },
    component: () => import("../views/PagamentoRegistro.vue"),
  },
  {
    path: "/pagamento-aprovado",
    name: "pagamento aprovado",
    meta: {
      title: "Pagamento - Seguro",
    },
    component: () => import("../views/PagamentoAprovado.vue"),
  },
  {
    path: "/pagamento-aprovado-registro",
    name: "pagamento aprovado registro",
    meta: {
      title: "Pagamento - Registro",
    },
    component: () => import("../views/PagamentoAprovadoRegistro.vue"),
  },
  {
    path: "/fatura-paga",
    name: "Fatura Paga",
    meta: {
      title: "Fatura - Seguro",
    },
    component: () => import("../views/FaturaPaga.vue"),
  },
  {
    path: "/fatura-cancelada",
    name: "Fatura Cancelada",
    meta: {
      title: "Fatura - Seguro",
    },
    component: () => import("../views/FaturaCancelada.vue"),
  },
  {
    path: "/step-by-step",
    name: "step-by-step",
    meta: {
      title: "Cotação - Seguro para bicicleta",
    },
    component: () => import("../views/CotacaoB.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "Login - Seguro para bicicleta",
    },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/checkout",
    name: "checkout",
    meta: {
      title: "Checkout - Seguro para bicicleta",
    },
    component: () => import("../views/Checkout.vue"),
  },
  {
    path: "/cotacao",
    name: "cotacao",
    meta: {
      title: "Cotação - Seguro para bicicleta",
    },
    component: () => import("../views/Cotacao.vue"),
  },
  {
    path: "/selo",
    name: "selo",
    meta: {
      title: "Selo - Seguro para bicicleta",
    },
    component: () => import("../views/Selo/Selo.vue"),
  },

  {
    path: "/selo/checkout-dados",
    name: "checkout-dados.vue",
    meta: {
      title: "Selo - Seguro para bicicleta",
    },
    component: () => import("../views/Selo/CheckoutDados.vue"),
  },
  {
    path: "/selo/checkout-confirmacao",
    name: "checkout-confirmacao.vue",
    meta: {
      title: "Selo - Seguro para bicicleta",
    },
    component: () => import("../views/Selo/CheckoutConfirmacao.vue"),
  },
  {
    path: "/selo/checkout-pagamento",
    name: "checkout-pagamento.vue",
    meta: {
      title: "Selo - Seguro para bicicleta",
    },
    component: () => import("../views/Selo/CheckoutPagamento.vue"),
  },
  {
    path: "/selo/checkout-sucesso",
    name: "checkout-sucesso.vue",
    meta: {
      title: "Selo - Seguro para bicicleta",
    },
    component: () => import("../views/Selo/CheckoutSucesso.vue"),
  },
  {
    path: "/selo/checkout-erro",
    name: "checkout-erro.vue",
    meta: {
      title: "Selo - Seguro para bicicleta",
    },
    component: () => import("../views/Selo/CheckoutErro.vue"),
  },
  {
    path: "/selo/checkout-pedido-pix",
    name: "checkout-pedido-pix.vue",
    meta: {
      title: "Selo - Seguro para bicicleta",
    },
    component: () => import("../views/Selo/CheckoutPedidoPix.vue"),
  },
  {
    path: "/home/bikesucesso",
    name: "home.vue",
    meta: {
      title: "Home - Seguro para bicicleta",
    },
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/obrigado",
    name: "obrigado",
    component: () => import("../views/Obrigado.vue"),
    meta: {
      title: "Obrigado - Seguro para bicicleta",
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: "/obrigadoMobile",
    name: "obrigadoMobile",
    component: () => import("../views/ObrigadoMobile.vue"),
    meta: {
      title: "Obrigado - Seguro para bicicleta",
      sitemap: {
        ignoreRoute: true,
      },
    },
  },

  {
    path: "/recuperarSenha",
    name: "recuperarSenha",
    meta: {
      title: "Recuperar Senha - Seguro para bicicleta",
    },
    component: () => import("../views/RecuperarSenha.vue"),
  },
  {
    path: "/definirSenha",
    name: "definirSenha",
    meta: {
      title: "Definir Senha - Seguro para bicicleta",
    },
    component: () => import("../views/DefinirSenha.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    meta: {
      title: "Admin - Seguro para bicicleta",
    },
    component: () => import("../views/Admin/LoginAdmin.vue"),
  },
  {
    path: "/resgate",
    name: "resgate",
    meta: { title: "Resgate - Seguro para bicicleta", requiresAuth: true },
    component: () => import("../views/Admin/SolicitacoesRegaste.vue"),
  },
  {
    name: "adicionar-acidente-pessoal",
    path: "/adicionar-acidente-pessoal/:id/:valorBike/:data/:nascimento/:uf",
    component: () => import("../views/Usuario/PlanoAcidentePessoal.vue"),
    meta: {
      title: "Acidente Pessoal - Seguro para bicicleta",
      requiresAuth: true,
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    name: "adicionar-acessorio",
    path: "/adicionar-acessorio/:id/:valorBike/:data",
    component: () => import("../views/Usuario/PlanoAcessorio.vue"),
    meta: {
      title: "Acessório - Seguro para bicicleta",
      requiresAuth: true,
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    name: "atualizar-dados",
    path: "/atualizarDados",
    component: () => import("../views/Usuario/AtualizarDados.vue"),
    meta: {
      title: "Acessório - Seguro para bicicleta",
      requiresAuth: false,
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: "/suporteEssor",
    name: "suporte sssor",
    meta: {
      title: "Suporte Essor - Seguro para bicicleta",
      requiresAuth: true,
    },
    component: () => import("../views/Admin/OrientacaoSinistroEssor.vue"),
  },
  {
    path: "/baixa-pagamento",
    name: "baixa-pagamento",
    meta: {
      title: "Baixa Pagamento - Seguro para bicicleta",
      requiresAuth: true,
    },
    component: () => import("../views/Admin/BaixaPagamento.vue"),
  },
  {
    path: "/analise-vendas",
    name: "vendas",
    meta: {
      title: "Painel de vendas - Seguro para bicicleta",
      requiresAuth: true,
    },
    component: () => import("../views/Admin/AnaliseVendas.vue"),
  },
  {
    path: "/segurados",
    name: "segurados",
    meta: { title: "Segurados - Seguro para bicicleta", requiresAuth: true },
    component: () => import("../views/Admin/Segurados.vue"),
  },
  {
    path: "/cobrancas",
    name: "cobrancas",
    meta: { title: "Cobranças - Seguro para bicicleta", requiresAuth: true },
    component: () => import("../views/Admin/Cobrancas.vue"),
  },
  {
    path: "/analise-cobrancas",
    name: "analise-cobrancas",
    meta: {
      title: "Analise Cobranças - Seguro para bicicleta",
      requiresAuth: true,
    },
    component: () => import("../views/Admin/AnaliseCobrancas.vue"),
  },
  {
    path: "/cupom",
    name: "cupom",
    meta: {
      title: "Analise Cobranças - Seguro para bicicleta",
      requiresAuth: true,
    },
    component: () => import("../views/Admin/CupomSeguro.vue"),
  },
  {
    path: "/cobranca-registro",
    name: "cobranca-registro",
    meta: {
      title: "Analise Cobranças - Registro para bicicleta",
      requiresAuth: true,
    },
    component: () => import("../views/Admin/AnaliseCobrancasAssinaturaRegistro.vue"),
  },
  {
    path: "/indicacao",
    name: "mgm-indicacao",
    meta: {
      title: "Indicação - Seguro para bicicleta",
      requiresAuth: true,
    },
    component: () => import("../views/Admin/MgmIndicacao.vue"),
  },
  {
    path: "/encurtador",
    name: "encurtador",
    meta: {
      title: "Encurtador - Seguro para bicicleta",
      requiresAuth: true,
    },
    component: () => import("../views/Admin/Encurtador.vue"),
  },
  {
    path: "/parceiro/",
    name: "parceiro",
    meta: {
      title: "Parceiro - Seguro para bicicleta",
      sitemap: {
        ignoreRoute: true,
      },
    },
    component: () => import("../views/Parceiro/Login.vue"),
  },
  {
    path: "/parceiro/:urlParceiro",
    name: "parceiro-url",
    meta: {
      title: "Parceiro - Seguro para bicicleta",
      sitemap: {
        ignoreRoute: true,
      },
    },
    component: () => import("../views/Cotacao.vue"),
  },
  {
    path: "/nova-conta-parceiro",
    name: "nova-conta-parceiro",
    component: () => import("../views/Parceiro/CriarConta.vue"),
    meta: {
      title: "Nova Conta Parceiro - Seguro para bicicleta",
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: "/recuperar-senha-parceiro",
    name: "recuperar-senha-parceiro",
    component: () => import("../views/Parceiro/RecuperarSenha.vue"),
    meta: {
      title: "Recuperar senha-parceiro - Seguro para bicicleta",
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: "/definir-senha-parceiro",
    name: "definir-senha-parceiro",
    meta: { title: "Definir Senha Parceiro - Seguro para bicicleta" },
    component: () => import("../views/Parceiro/DefinirSenha.vue"),
  },
  {
    path: "/cliente/",
    component: () => import("../components/DashboardUsuario.vue"),
    children: [
      {
        name: "cliente",
        path: "/",
        meta: { title: "Cliente - Seguro para bicicleta" },
        component: () => import("../views/Usuario/MinhasApolices.vue"),
      },
      {
        name: "editar-conta",
        path: "/cliente/editar-conta",
        meta: { title: "Editar conta - Seguro para bicicleta" },
        component: () => import("../views/Usuario/EditarConta.vue"),
      },
      {
        name: "minha-indicacoes",
        path: "/cliente/minha-indicacoes",
        meta: { title: "Minhas indicações - Seguro para bicicleta" },
        component: () => import("../views/Usuario/SaldoIndicacao.vue"),
      },
    ],
  },
  {
    path: "/dashboard/",
    component: () => import("../components/Dashboard.vue"),
    children: [
      {
        name: "dashboard",
        path: "/",
        meta: { title: "Dashboard - Seguro para bicicleta" },
        component: () => import("../views/Parceiro/Home.vue"),
      },
      {
        name: "link-parceiro",
        path: "/dashboard/link-parceiro",
        meta: { title: "Dashboard - Seguro para bicicleta" },
        component: () => import("../views/Parceiro/LinkParceiro.vue"),
      },
      {
        path: "/dashboard/novo-seguro",
        name: "novo-seguro",
        meta: { title: "Novo Seguro - Seguro para bicicleta" },
        component: () => import("../views/Parceiro/SimulacaoPlano.vue"),
      },
      {
        path:
          "/dashboard/dados-cliente/:marcaBike/:modeloBike/:valorBike/:migracao/:idKakau/:cliente/:coberturaAcessorio/:marcaAcessorio/:nomeAcessorio/:valorAcessorio/:nascimento/:ufCliente",
        name: "dados-cliente",
        component: () => import("../views/Parceiro/NovoSeguro.vue"),
        meta: {
          title: "Dados Cliente - Seguro para bicicleta",
          sitemap: {
            ignoreRoute: true,
          },
        },
      },
      {
        path: "/dashboard/seguros",
        meta: { title: "Seguros - Seguro para bicicleta" },
        component: () => import("../views/Parceiro/Seguros.vue"),
      },
      {
        path: "/dashboard/dados-bancarios",
        meta: { title: "Dados Bancarios - Seguro para bicicleta" },
        component: () => import("../views/Parceiro/DadosBancarios.vue"),
      }
    ],
  },
  {
    path: "/finalizar-transferencia",
    name: "finalizar-transferencia",
    meta: { title: "Bike Registrada - Transferência de bike" },
    component: () => import("../views/TransferenciaBike.vue"),
  },
  {
    path: "/liberar-email",
    name: "/liberar-email",
    meta: { title: "Bike Registrada - Seguro para bicicleta" },
    component: () => import("../views/Admin/LiberarEmail.vue"),
  },
  {
    path: "/retencao",
    name: "/retencao",
    meta: { title: "Bike Registrada - Seguro para bicicleta" },
    component: () => import("../views/Admin/Retencao.vue"),
  },
  {
    path: "/retencao-conta-b",
    name: "/retencao-conta-b",
    meta: { title: "Bike Registrada - Seguro para bicicleta" },
    component: () => import("../views/Admin/RetencaoContaB.vue"),
  },
  {
    path: "/estorno",
    name: "estorno",
    meta: {
      title: "Estorno - Seguro",
    },
    component: () => import("../views/Estorno.vue"),
  },
  {
    path: "/estorno-sucesso",
    name: "estorno sucesso",
    meta: {
      title: "Estorno - Seguro",
    },
    component: () => import("../views/EstornoSucesso.vue"),
  },
  {
    path: "/beneficios",
    name: "beneficios",
    meta: {
      title: "Beneficios - Seguro",
    },
    component: () => import("../views/Admin/Beneficios.vue"),
  },
];
